import React, { useContext } from "react";

import GlobalContext from "../../context/GlobalContext";
import imgC from "../../assets/image/hero/gallery.jpg";

const CTA  = ({ className, ...rest }) => {
  const gContext = useContext(GlobalContext);

  return (
    <div className={className} {...rest} style={{position: "relative", top:"-2px", zIndex:"100"}}>
      <div className="pt-14 pt-md-19 pt-lg-10 pb-15 pb-md-18 pb-lg-25 mb-lg-1">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-6 col-lg-6 col-md-10 col-sm-11"
            data-aos="fade-right"
            data-aos-duration={500}
          >
            <div
              className="bg-images d-flex align-items-center justify-content-center max-w-540 py-18 py-sm-28 rounded-10 mx-auto"
              css={`
                background-image: url(${imgC});
              `}
            >
              <a
                href="/#"
                className="video-btn sonar bg-blue-3-op5 text-white circle-98 font-size-8"
                onClick={(e) => {
                  e.preventDefault();
                  gContext.toggleVideoModal();
                }}
                style={{visibility:"hidden"}}
              >
                <i className="fa fa-play" />
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-10 col-sm-11">
            <div className="pt-9 pt-lg-0 pr-xs-10 pr-sm-5 pr-md-25 pr-lg-0 pl-xl-16">
              <p
                className="font-size-5 mb-7 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >
              Turning your online videos into an interactive experience has a vast number of valuable benefits that serve both artists and galleries.
              </p>
              <p
                className="font-size-5 mb-0 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >DIGMA's stand-alone process of making your footage immersive can help your gallery share information on artists, style, and the establishment itself, all within a unique viewing experience that brings the connection between the exhibition and the viewing guests into a new and exciting era.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default CTA;
