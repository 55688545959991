import React from "react";
import {Link} from "gatsby";
import { Helmet } from "react-helmet";
import PageWrapper from "../../../components/PageWrapper";
import Hero from "../../../sections/galleries/Hero";
import Main from "../../../sections/galleries/Main";
import Features from "../../../sections/common/Features";
import VideoDemo from "../../../sections/common/VideoDemoNew";
import Wave from "../../../sections/common/Wave";
import CTA from "../../../sections/CTA/CTA";
import SEO from '../../../components/SEO';
import imgC from "../../../assets/image/metaimg/galleries.jpg";
const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          headerButton: (
            <>
              <Link to="/contact" className="btn btn btn-dodger-blue-2 btn-medium rounded-5 font-size-3">
                Try for free
              </Link>
            </>
          ),
          footerStyle: "digma",
        }}>
        <SEO
          title="Interactive remote tours for galleries and exhibitions"
          description="Digma's Advanced Interactive Player compliments drone or gimbal footage and brings the exhibition right to the comfort of your home."
          image={imgC}
        />    
        <Helmet>
        <link
          href={'https://cdn.digma.io/dist/digma.min.1.2.0.css?v=galleries'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.1.2.0.js?v=galleries'}></script>         
          <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
        </Helmet>                 
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/tours">Tours</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li> 
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/tours/galleries">Galleries</Link>
              </li>                            
              </ol>
           </nav>
        </div>                
        <Hero />
        <Wave pos="top" color="#0c426d" />
        <VideoDemo className="bg-digma-dark" title="Our Latest Interactive Video" name="Interactive Art Gallery" projectId="e3e2de77-696b-4dc9-b0c4-633feb0a6dbe" />
        <Wave pos="bottom" color="#F7F9FC" background="#0c426d" /> 
        <Main className="grey" />
        <Features />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
